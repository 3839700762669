import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MyTextField = ({ name, label, handleChange, props }) => (
  <TextField
    id={name}
    name={name}
    label={label}
    value={props[name]}
    onChange={handleChange}
    variant="standard"
    size="small"
    style={{ width: '100%' }}
    InputProps={{ disableUnderline: true }} />
)

export default function BasicGrid(props) {
  const { t } = useTranslation()

  const handleChange = (event) => {
    const { name, value } = event.target
    props.setPhoneProps(prevPhoneProps => ({ ...prevPhoneProps, [name]: value }))
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item xs={8}>
          <MyTextField
            name="phoneNumber"
            label={t("Phone number")}
            handleChange={handleChange}
            props={props} />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item xs={5}>
          <MyTextField
            name="plan"
            label={t("Plan")}
            handleChange={handleChange}
            props={props} />
          <MyTextField
            name="planPrice"
            label={t("Price")}
            handleChange={handleChange}
            props={props} />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <MyTextField
            name="planDateOne"
            label={t("Period 1")}
            handleChange={handleChange}
            props={props} />
          <MyTextField
            name="planDateTwo"
            label={t("Period 2")}
            handleChange={handleChange}
            props={props} />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid item xs={10}>
            <MyTextField
              name="spendings"
              label={t("Spendings")}
              handleChange={handleChange}
              props={props} />
          </Grid>
          <Grid item xs={10}>
            <MyTextField
              name="question"
              label={t("Question")}
              handleChange={handleChange}
              props={props} />
          </Grid>
          <Grid 
            item 
            xs={10} 
            style={{ 
              border: 'solid 1px black', 
              padding: '12px', 
              marginTop: '12px', 
              marginBottom: '12px',
              borderRadius: '5px'
            }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Grid item xs={5}>
                <MyTextField
                  name="numberOne"
                  label={t("Gbs")}
                  handleChange={handleChange}
                  props={props} />
              </Grid>
              <Grid item xs={5}>
                <MyTextField
                  name="numberTwo"
                  label={t("Mins")}
                  handleChange={handleChange}
                  props={props} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Grid item xs={5}>
                <MyTextField
                  name="subscription"
                  label={t("What")}
                  handleChange={handleChange}
                  props={props} />
              </Grid>
              <Grid item xs={5}>
                <MyTextField
                  name="location"
                  label={t("Where")}
                  handleChange={handleChange}
                  props={props} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center">
              <Grid item xs={10}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Grid item xs={4}>
                    <MyTextField
                      name="highLightFooter"
                      label={t("Highlighted text")}
                      handleChange={handleChange}
                      props={props} />
                  </Grid>
                  <Grid item xs={8}>
                    <MyTextField
                      name="nonHighLightFooter"
                      label={t("Other text")}
                      handleChange={handleChange}
                      props={props} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MyTextField
                  name="buttonText"
                  label={t("Button text")}
                  handleChange={handleChange}
                  props={props} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          <Grid item xs={2}>
            <MyTextField
              name="remainingSum"
              label={t("Amount")}
              handleChange={handleChange}
              props={props} />
          </Grid>
          <Grid item xs={6}>
            <MyTextField
              name="remainingTime"
              label={t("Remaining time")}
              handleChange={handleChange}
              props={props} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          <Grid item xs={8}>
            <MyTextField
              name="remainingSubText"
              label={t("Sub-text")}
              handleChange={handleChange}
              props={props} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          <Grid item xs={2}>
            <MyTextField
              name="pricePerSms"
              label={t("Amount")}
              handleChange={handleChange}
              props={props} />
          </Grid>
          <Grid item xs={6}>
            <MyTextField
              name="perSmsText"
              label={t("Remaining time")}
              handleChange={handleChange}
              props={props} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          <Grid item xs={8}>
            <MyTextField
              name="removeLimit"
              label={t("Sub-text")}
              handleChange={handleChange}
              props={props} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}