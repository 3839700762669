import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import PhoneSection from "../components/phone-component"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { slideFromRight } from "../components/hero.css.ts"

const Fallback = (props) => {
  console.warn(`No component found for: ${props.blocktype}`)
  return false
}

export default function Homepage(props) {
  const homepage = props.data.contentfulHomepage

  return (
    <Layout {...homepage}>
      {homepage.image && (
        <GatsbyImage
          alt={homepage.image.alt}
          image={getImage(homepage.image)}
          style={{
            animation: "fromBlackAndWhite 2s 1 normal forwards, slideIn45 2s 1 normal forwards",
            zIndex: '-1',
            maxWidth: '50vw',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        />
      )}
      {homepage.blocks.slice(0,2).map((block, i) => {
        const Component = sections[block.blocktype] || Fallback
        return <Component key={block.id} index={i} {...block} />
      })} 
      <PhoneSection />
      {homepage.blocks.slice(2,homepage.blocks.length).map((block, i) => {
        const Component = sections[block.blocktype] || Fallback
        return <Component key={block.id} index={i} {...block} />
      })}
    </Layout>
  )
}

export const query = graphql`
  query homePage($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }    
    contentfulHomepage(node_locale: {eq: $language}){
      id
      title
      description
      image {
        id
        url
        gatsbyImageData
        alt
      }
      blocks: content {
        id
        blocktype
        ...HomepageHeroContent
        ...HomepageFeatureContent
        ...HomepageFeatureListContent
        ...HomepageCtaContent
        ...HomepageLogoListContent
        ...HomepageTestimonialListContent
        ...HomepageBenefitListContent
        ...HomepageStatListContent
        ...HomepageProductListContent
        # New component fragment
        ...HomepageProductConfigContent
      }
    }
  }
`;

