import * as React from "react"
import {
  Container,
  Section,
  Heading,
  Subhead,
  Kicker,
  Flex,
  Box,
} from "./ui"

import Phone from './custom/phone'
import PhoneFields from './material/phoneFields'

import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function LogoList(props) {
  const { t } = useTranslation()
  const [phoneProps, setPhoneProps] = React.useState({
    phoneNumber: t('phoneNumber'),
    plan: t('plan'),
    planCurrency: t('planCurrency'),
    planPrice: t('planPrice'),
    planDateOne: t('planDateOne'),
    planDateTwo: t('planDateTwo'),
    spendings: t('spendings'),
    question: t('question'),
    numberOne: t('numberOne'),
    numberOneUnit: t('numberOneUnit'),
    subscription: t('subscription'),
    numberTwo: t('numberTwo'),
    numberTwoUnit: t('numberTwoUnit'),
    location: t('location'),
    highLightFooter: t('highLightFooter'),
    nonHighLightFooter: t('nonHighLightFooter'),
    buttonText: t('buttonText'),
    remainingSum: t('remainingSum'),
    remainingTime: t('remainingTime'),
    remainingSubText: t('remainingSubText'),
    pricePerSms: t('pricePerSms'),
    perSmsText: t('perSmsText'),
    removeLimit: t('removeLimit')
  })

  return (
    <Section style={{padding: 0}}>
      <Container>
        <Flex gap={4} variant="responsive">
          <Box 
            width="half" 
            style={{ 
              borderRadius: "15px",
              border: "1px solid black",
              backgroundColor: "rgba(137,136,152,.5)",
              padding: "24px",
              color: "white",
             }}>
            <Heading as="h1">
              <Kicker>{t("It is our mission to engage visitors")}</Kicker>
              {t("You imagine it, we build it")}
            </Heading>
            <Subhead as="h2">{t("Try changing the fields...")}</Subhead>
            <PhoneFields {...phoneProps} setPhoneProps={setPhoneProps}/>
          </Box>
          <Box width="half" style={{ position: 'static' }}>
            <Phone {...phoneProps}  />
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}

